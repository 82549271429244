<template>
	<div class="NotFound">
		<div class="container">
			<h1>404 Not Found</h1>
		</div>
	</div>
</template>


<script>
export default {
	name: 'NotFound',
	components: {},
}
</script>


<style scoped lang="less">
.NotFound {
	
}
</style>
